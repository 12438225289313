@import "~antd/dist/antd.css";

/* ============================================================= */
/* reset css */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
select,
option,
p,
span,
ul,
ol,
li,
a,
button {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  background: transparent;
  border: 0;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  scrollbar-face-color: #808080;
  scrollbar-arrow-color: #808080;
  scrollbar-track-color: #cbd5d7;
  scrollbar-shadow-color: #808080;
  scrollbar-highlight-color: #808080;
  scrollbar-3dlight-color: #808080;
  scrollbar-darkshadow-color: #202020;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  cursor: pointer;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

a:focus {
  border: 1px solid black;
}

em {
  font-style: italic;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fixed {
  position: absolute;
}
#body > div.fixed {
  position: fixed;
}

#section {
  height: 100%;
  min-height: 100vh;
  background: #fafafa;
}

button {
  margin: 0;
  padding: 0;
}

.contextMenuWrapper {
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
  text-align: center;
}

.editorBtnImage {
  width: 100%;
}

.settingSubmitBtn {
  width: 70%;
  margin: 0 auto;
  border: solid 1px #51a5c1;
  border-radius: 6px;
  background-color: #51a5c1;
  color: #fff;
  height: 44px;
  font-size: 1rem;
}

/*  COMMON MODAL STYLE  */

.common-modal-area {
  padding: 2rem;
}

/*  COMMON MODAL STYLE  */

.common-modal-area .title {
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
  padding-bottom: 1em;
}

.common-modal-area .title span {
  padding: 0 1.5rem;
  width: 2rem;
  display: inline-block;
  text-align: center;
}

.user-avatar-img {
  border-radius: 50%;
  width: 100%;
}

/*  HOME MENU AREA  */

.home-menu {
  padding: 1rem 1rem;
  background-color: #fff;
}

.home-menu .buttons {
  padding: 1rem 0;
}

.home-menu .buttons button {
  padding: 0.4rem;
  margin-right: 0.6rem;
  font-size: 1.2rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.1rem;
  text-align: left;
  color: #51a5c1;
  border: solid 1px;
  border-radius: 0.3rem;
}

.home-menu .tool-tip {
  text-align: right;
}

.homeMenuButtonWrapper {
  margin: 0px 16px 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homeMenuButtonWrapperNewsFeed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  margin: 0;
  padding: 1rem 1rem 0.8rem 1rem;
}

/*FIXME*/
.band-guide-area {
  padding: 3rem 1rem 1rem 1rem;
  width: 100%;
  text-align: center;
}

.band-use-guide-area .title {
  font-size: 1.5rem;
  font-weight: bold;
  padding-bottom: 1rem;
}

.band-use-guide-area .description {
  font-size: 1rem;
  padding-bottom: 2rem;
}

.band-use-guide-area .buttons {
  display: flex;
  justify-content: center;
}

.band-use-guide-area .buttons button {
  padding: 0 0.5rem;
}

.band-search-area {
  display: flex;
  flex-flow: column;
  margin: 1rem auto;
  max-width: 500px;
}

.band-search-input {
  background-image: url("../images/icon-search.svg");
  background-repeat: no-repeat;
  background-position: 10px center;
  background-color: rgba(0, 0, 0, 0.1);
  background-size: 15px 15px;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 2.3rem;
  margin: 0 1rem;
  border-radius: 4px;
  border: 1px solid #d6dbdc;
}

.band-search-input::placeholder {
  color: #c7c7cc;
}

.band-search-result-area .title {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.6rem 0rem;
}

/*  BAND LIST  */
.band-cover-selector-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 0.5rem;
}
.band-cover-selector-grid > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.band-cover-selector-grid > *:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
}
.band-cover-selector-grid > *:nth-child(3) {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}
.band-cover-selector-grid > *:nth-child(4) {
  -ms-grid-row: 1;
  -ms-grid-column: 4;
}
.band-cover-selector-grid > *:nth-child(5) {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
}

.band-cover-selector-grid img {
  width: 100%;
  padding: 0.5rem;
  max-height: 100px;
}

.band-cover-selector-grid video {
  width: 100%;
  padding: 0.5rem;
  max-height: 100px;
}

.dataTimeInput {
  width: 80% !important;
}

.dataTimeInput input {
  text-align: right;
}

.band-list {
  padding: 0 0 2rem 0;
}

.band-list img {
  width: 100%;
  border-radius: 0.3rem;
  min-height: 97%;
  object-fit: cover;
}

.band-list .title {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  padding: 0 0 0.5rem 0;
}

.band-list .description {
  font-size: 0.9rem;
  margin: 0;
  padding: 0 0 0.5rem 0;
  white-space: pre-line;
  word-break: break-all;
}

.band-list .info {
  font-size: 0.9rem;
  color: #8e8e93;
  margin: 0;
  padding: 0 0 0.5rem 0;
}

/*  BAND LIST  */

.ui.grid.agenda {
  margin: 0;
  padding: 0;
}

.ui.grid.agenda .row {
  margin: 0;
  padding: 0;
}

.ui.grid.agenda .column {
  margin: 0;
  padding: 0;
}

.ui.grid.schedule-attach-files {
  margin-top: 0;
  margin-bottom: 0;
}

.ui.grid.schedule-attach-files .column {
  margin-top: 0;
  margin-bottom: 0;
}

.ui.grid.band-cover-selector-grid .column {
  margin-top: 0;
  margin-bottom: 0;
}

.ui.column.grid.band-category-grid {
  margin: 0;
  padding: 0;
}

.ui.column.grid.band-category-grid .column {
  margin: 0;
  padding: 0 0.1rem;
  height: auto !important;
  text-align: center;
}

.ui.column.grid.band-cover-grid {
  margin: 0;
  padding: 0;
}

.ui.column.grid.band-cover-grid .column {
  margin: 0;
  padding: 0 0.1rem;
  height: auto !important;
  text-align: center;
}

.ui.column.grid.band-grid {
  margin: 0;
  padding: 0;
}

.ui.column.grid.band-grid .column {
  margin: 0;
  padding: 0 0.2rem;
  height: auto !important;
  text-align: center;
}

.ui.column.grid.content-grid {
  margin: 0;
  padding: 0;
}

.ui.column.grid.content-grid .column {
  margin: 0;
  padding: 0 0.2rem;
  height: auto !important;
  text-align: center;
}

.ui.column.grid.calendar-grid {
  margin: 0;
  padding: 0;
}

.ui.column.grid.calendar-grid .header {
  background-color: #e5e5ea;
  font-size: 1rem;
  font-weight: bold;
}

.ui.column.grid.calendar-grid .column {
  margin: 0;
  padding: 1rem 0;
  height: auto !important;
  text-align: center;
}

.image-inner-post {
  width: 100%;
}

.video-inner-post {
  width: 100%;
}

.file-inner-post {
  width: 100%;
  background-color: #eee;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}

.poll-inner-post {
  width: 100%;
  background-color: #eee;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}

.schedule-inner-post {
  width: 100%;
  background-color: #eee;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}

.fixRound {
  border: 1px solid #51a5c1;
  border-radius: 56px;
  font-size: 0.8em;
  color: #51a5c1;
  padding: 5px;
}

.band-list-image {
  max-height: 250px;
}

.button-additional-img-0 {
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.button-additional-img-1 {
  cursor: pointer;
  position: absolute;
  right: 40px;
}

.home-desktop-additional-img-1 {
  margin-right: 5px;
}

/* react-datetime */
.rdtPicker {
  width: 100%;
}

.bandHomeVideoHover:hover {
  opacity: 0.5;
}

.paragraph iframe {
  width: 100% !important;
}

td {
  border: 1px solid lightgray;
}

.rdtPicker td {
  border: none;
}

.fr-emoticon.fr-deletable.fr-emoticon-img {
  background-repeat: no-repeat !important;
  background-size: 1.5rem !important;
  padding-left: 2rem;
}

.dim {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10001;
}

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.rc-time-picker-clear {
  display: none !important;
}

.material-icons {
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.second-toolbar #logo {
  display: none !important;
}

/***************************************************************************
                            myBand 부분 Header Style
***************************************************************************/
/*bg*/
.wrapper {
  background-color: #edf1f2;
  width: 100%;
  height: auto;
  min-height: 100vh;
}

.ui.grid > * {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.band_join_button {
  cursor: pointer;
}

.band-list .description,
.band-list .info {
  line-height: 1rem;
}

.band-guide-area {
  padding: 3rem 0rem 2rem;
}

.ui.grid > .row {
  padding: 1rem !important;
}

.ant-comment-avatar img {
  width: 100%;
  height: 100%;
}

.ant-modal-close-x {
  display: none;
}

.ant-comment-actions {
  float: right;
}

.ant-dropdown-menu {
  z-index: 999999999999999999999 !important;
}

.ant-dropdown.ant-dropdown-placement-topLeft {
  z-index: 999999999999999 !important;
}

.react-contextmenu-wrapper {
  display: flex;
  justify-content: center;
}

.rbc-event {
  background-color: #51a5c1 !important;
  font-size: 11px !important;
  padding: 0;
  border-radius: 3px !important;
}

@media all and (max-width: 768px) {
  .rbc-btn-group + .rbc-btn-group,
  .rbc-btn-group + button {
    /* margin-top: 10px; */
  }
}

.rbc-date-cell.rbc-off-range p {
  color: #999999 !important;
}

.userWrap {
  margin-left: auto;
  flex: none;
  width: 36px;
  height: 36px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.search-noInput section {
  margin-bottom: 2%;
}
/*searchBoxWrap*/
.searchBoxWrap {
  width: 100%;
  height: auto;
  margin-top: 2%;
  margin-bottom: 4%;
  text-align: center;
}
.blind {
  overflow: hidden !important;
  position: absolute !important;
  height: 1px !important;
  width: 1px !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
}
.searchBox {
  width: 76%;
  padding: 2px 8px;
  border: 1px solid #d6dbdc;
  border-radius: 4px;
  color: #000;
  font-size: 15px;
  line-height: 23px;
  background-color: #edf1f2;
  box-sizing: border-box;
}
.searchBox::placeholder {
  color: #c7c7cc;
}
.btnSearch {
  position: absolute;
  margin-left: -40px;
  padding: 4.5px 12px;
  cursor: pointer;
}
.iconSearch {
  width: 16px;
  height: 16px;
  background-image: url("../images/icon_search.svg");
  display: inline-block;
}
/*.explore-cat-wrap*/
.explore-cat-wrap {
  position: relative;
}
.explore-cat:after {
  content: url("../images/btn_next.svg");
  width: 60px;
  height: 85%;
  display: none;
  position: absolute;
  top: 24px;
  right: 0;
  padding: 4.3% 0% 4.3% 4%;
  background: linear-gradient(90deg, rgba(237, 241, 242, 0) 0%, rgba(237, 241, 242, 1) 50%);
  box-sizing: border-box;
  cursor: pointer;
}
.explore-cat:before {
  content: url("../images/btn_prev.svg");
  width: 60px;
  height: 85%;
  display: none;
  position: absolute;
  top: 24px;
  left: 0;
  padding: 4.3% 4% 4.3% 0%;
  background: linear-gradient(270deg, rgba(237, 241, 242, 0) 0%, rgba(237, 241, 242, 1) 50%);
  box-sizing: border-box;
  cursor: pointer;
}
.explore-cat {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: hidden;
}
.explore-cat li {
  width: calc((100% - 16%) / 9);
  min-width: 70px;
  height: auto;
  margin-right: 1.6%;
  display: inline-block;
}
.explore-cat li:last-child {
  margin-right: 0px;
}
.explore-cat li * {
  width: 100%;
  height: auto;
}
.explore-cat li a img {
  display: block;
  box-shadow: 2px 2px 6px rgba(0, 165, 193, 0.2);
  border-radius: 6px;
}
/*new-band-wrap*/
.new-band-list li,
.popular-band-list li {
  margin-bottom: 12px;
}
.new-band-list li a,
.popular-band-list li a {
  display: flex;
  align-items: center;
}
.bandCoverWrap {
  width: 56px;
  min-width: 56px;
  height: 56px;
  margin-right: 12px;
  overflow: hidden;
}
.bandCoverWrap img {
  width: 100%;
  height: 100% !important;
  display: block;
}
.bandNameWrap {
  width: calc(100% - 68px);
}
.bandNameWrap h6 {
  font-size: 1.1rem;
}
.description {
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}
.bandInfo,
.bandInfo * {
  color: #8e8e93;
}
/*popular-band-list*/
.popular-band-list .bandName {
  position: relative;
}
.popular-band-list .bandRanking:before {
  content: "BEST ";
}
.popular-band-list .bandRanking {
  position: absolute;
  right: 0;
  color: #db0e61;
  border: 1px solid #db0e61;
  font-size: 11px;
  padding: 0px 6px;
  border-radius: 11px;
  line-height: 21px;
}

/***************************************************************************
                          5.responsive
***************************************************************************/
@media (max-width: 1024px) {
  h5 {
    font-size: 14px;
  }
  h6 {
    font-size: 14px;
    line-height: 18px;
  }
  .popular-band-list .bandRanking {
    line-height: 17px;
  }
  .bandInfo,
  .bandInfo * {
    font-size: 13px;
    line-height: 17px;
  }
  .search-noInput section,
  .searchBoxWrap {
    margin-bottom: 3%;
  }
  .searchBox {
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 768px) {
  .search-noInput section,
  .searchBoxWrap {
    margin-bottom: 6%;
  }
  .searchBox {
    width: 88%;
  }
  .explore-cat:before,
  .explore-cat:after {
    display: block;
  }
}
@media (max-width: 591px) {
  .bandNameWrap {
    width: calc(100% - 70px - 1.6%);
  }
  h6 {
    margin-bottom: 0px;
  }
  .explore-cat:before {
    height: 85px;
    padding: 30px 48px 30px 0px;
  }
  .explore-cat:after {
    height: 85px;
    padding: 30px 0px 30px 48px;
  }
}

.contentsWrap {
  position: relative;
  max-width: 1200px;
  margin: 0px auto 0px;
  padding: 16px 8px 50px;
  box-sizing: border-box;
}

.contentsWrapSpacer {
  height: 55px;
  display: block;
}

.DayPickerInput input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d6dbdc;
  height: 30px;
  padding: 0 0.5rem;
}

.rc-tree-select-selection--multiple {
  min-height: 32px !important;
}

.rc-tree-select-enabled.rc-tree-select-focused .rc-tree-select-selection {
  border-color: #40a9ff;
}

.list-item :hover {
  background-color: #d0cfcf;
  cursor: pointer;
}

.searchUserInput ::placeholder {
  color: #d9d9d9;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none !important;
}

.fr-popup.fr-desktop.fr-ltr.fr-active .fr-buttons {
  display: none;
}

.language-selector {
  vertical-align: "top";
  font-weight: 400;
  background: url("../images/icon_arrow_down.png") no-repeat 40px;
}
select {
  vertical-align: top;
  font-weight: 400;
}

select::-ms-expand {
  display: none;
}

.ant-menu-item-active,
.ant-menu-item:hover,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #51a5c1;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #f0f8fb !important;
}

.explore-cat-news li {
  margin-right: 7px;
}

.singleCardWrap .iYpjVw {
  border: 1px solid rgb(219, 219, 219);
  border-bottom: 0;
}

.singleCardWrap .iYpjVw + div {
  border-radius: 0;
}

.routeToPost {
  opacity: 0;
}

.userWrap {
  display: none;
}

.singleCardWrap .ui.grid .six.wide.column {
  width: auto !important;
  margin-right: 16px;
}

.singleCardWrap .ui.grid .six.wide.column .jnxcKq {
  margin-left: 0;
}

.singleCardWrap .ui.grid .four.wide.column {
  margin-left: auto;
}

.searchBoxWrap .ant-input-search {
  width: 70% !important;
  border-radius: 17px;
}
/* 
.search-noInput section,
.searchBoxWrap {
  margin-bottom: 4%;
  margin-bottom: 3%;
  margin-bottom: 6%;
} */

.userWrap {
  display: none;
}

.bandNameWrap .bandName,
.bandNameWrap .description {
  margin-bottom: 2px !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: hsl(0, 3%, 85%) !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(201, 201, 201) !important;
}

/* react-date-time */

.rdt {
  position: relative;
  margin-top: 0.5rem;
  width: 100%;
}
.rdtPicker {
  width: 100%;
}

.rdtPicker td {
  border: none;
}
.rdt {
  margin-top: 0 !important;
}

.rdtPicker {
  position: inherit !important;
}

.rdt input {
  width: 100%;
  border: 1px solid #eee;
  padding: 0.5rem;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #f0f8fb !important;
}

.rbc-month-view {
  background-color: #fff !important;
}

.rbc-off-range-bg {
  background: #f4f4f4 !important;
}

.rbc-agenda-table {
  background-color: #fff !important;
  text-align: center !important;
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: center !important;
}

.rbc-agenda-content {
  background-color: #fff !important;
}
