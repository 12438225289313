.inviteMemberButton {
    width: fit-content;
    height: 32px;
    padding: 0 12px;
    cursor: pointer;
    text-align: center;
    border-color: #51a5c1;
    color: white;
    border-radius: 8px;
    background-color: #51a5c1;
    font-weight: bold;
}

.inviteMemberButton:hover,
.inviteMemberButton:active,
.inviteMemberButton:focus {
    background-color: #4491ab !important;
    border-color: #4491ab !important;
    color: #fff !important;
}

.rc-tree-select {
    margin-bottom: 12px !important;
}

.MuiListItem-root.MuiListItem-gutters.MuiListItem-secondaryAction {
    padding-left: 4px !important;
}

.MuiListItemSecondaryAction-root {
    right: 4px !important;
}
