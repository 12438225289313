.band-create-wrapper {
    padding: 16px;
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-color: #edf1f2;
}
.band-create-contentsWrap {
    position: relative;
    margin: 0 auto;
    padding: 20px;
    max-width: 900px;
    border: 1px solid rgb(219, 219, 219);
    border-radius: 4px;
    background-color: #FFF;
    box-sizing: border-box;
}
.make-band .con-header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}
.make-band .roll-back {
    margin-right: 12px;
    cursor: pointer;
    width: 10px;
    height: 24px;
}
.make-band .roll-back img {
    width: 100%;
    height: auto;
}
.make-band .page-title {
    display: inline-block;
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #2c70b9;
    line-height: 1.2;
}
.page-title-sub {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #cbd4d4;
    line-height: 1.4;
}
.page-title-sub:before {
    content: "|";
    margin: 0 8px;
    font-size: 14px;
    vertical-align: 2px;
}
.make-band.set-cat .con-body {
    display: flex;
    flex-wrap: wrap;
}
.make-band.set-cat .single-cat {
    margin-right: 16px;
    margin-bottom: 16px;
    width: calc( ( 100% - 82px ) / 6 );
    height: auto;
    overflow: visible;
    cursor: pointer;
}
.make-band.set-cat .single-cat:nth-child(6n) {
    margin-right: 0px;
}
.make-band.set-cat .single-cat img {
    width: 100%;
    height: auto;
    border-radius: 6px;
    box-shadow: 2px 2px 4px rgba(0,165,193,0.2);
}
