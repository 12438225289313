/*slider*/
.image-gallery {
  position: relative;
}
.image-gallery-slide {
  width: 100%;
  height: 22vw;
  overflow: hidden;
}
.image-gallery-slide picture img {
  width: 100%;
  height: auto;
}
.slider_l {
  display: block;
}
.slider_m {
  display: none;
}
.slider_s {
  display: none;
}
.image-gallery-slide .image-gallery-description-wrap {
  position: relative;
  margin: auto;
  max-width: 1200px;
  width: 100%;
  height: auto;
}

.image-gallery-slide .image-gallery-description.busan {
  position: absolute;
  left: 16px;
  bottom: 4vw;
  padding: 20px 0px;
  font-size: 3vw;
  line-height: 4vw;
  font-weight: 700;
  color: #fff;
  background: transparent;
}

.image-gallery-slide .image-gallery-description {
  position: absolute;
  left: 16px;
  bottom: 16px;
  padding: 20px 30px;
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  color: #51a5c1;
  background: rgba(255, 255, 255, 0.75);
}
.image-gallery-slide .image-gallery-description a {
  display: inline-block;
  margin-top: 8px;
  padding: 5px 8px;
  border: 1px solid #51a5c1;
  border-radius: 4px;
  font-size: 16px;
  color: #51a5c1;
}
.image-gallery-bullets {
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
  max-width: 1200px;
  margin: auto;
}
.image-gallery-bullets .image-gallery-bullets-container {
  text-align: right;
}

/*contents-header*/
.content_wrap {
  max-width: 1200px;
  margin: auto;
  box-sizing: border-box;
}
.homeMenuButtonWrapper .sc-ifAKCX.jsjrWJ button {
  margin: 0px 2px;
  padding: 5px 8px;
  border: 1px solid #808080;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  color: #808080;
}
.homeMenuButtonWrapper .sc-ifAKCX.jsjrWJ button:hover,
.leaderWrap button:hover {
  background: #e5eaf9;
}
.homeMenuButtonWrapper .sc-ifAKCX.jsjrWJ button.select {
  background: #51a5c1;
  border: 1px solid #51a5c1;
}
.homeMenuButtonWrapper,
.homeMenuButtonWrapperNewsFeed {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sc-EHOje.ecAJHT {
  display: inherit;
}
.btn-hambuger {
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 7px 9px;
  border-radius: 50%;
  background: #eff3f3;
  cursor: pointer;
  box-sizing: border-box;
}
.btn-hambuger:hover {
  background: #e4e6e8;
  transition: all 0.6s;
}
.bar1,
.bar2,
.bar3 {
  width: 14px;
  height: 2px;
  background-color: #51a5c1;
  margin: 3px 0;
  transition: 0.4s;
}
/*contents-noband*/
.band-guide-area-wrap {
  margin-top: 50px;
  margin-bottom: 50px;
}
.band-guide-area {
  text-align: center;
}
.band-guide-area.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}
.band-guide-area.description {
  margin-bottom: 28px;
}
.band-guide-area.buttons button {
  margin: 0px 10px;
  padding: 0px;
  width: calc(50% - 22px);
  max-width: 130px;
}
.band-guide-area.buttons button img {
  width: 100%;
  height: 100%;
}
.leaderWrap {
  text-align: center;
}
.leaderWrap button {
  padding: 5px 8px;
  border: 1px solid #51a5c1;
  border-radius: 4px;
  font-size: 13px;
  font-weight: bold;
  color: #51a5c1;
}
/*contents-bandlist*/

.ui.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 0;
  margin: 0;
}
.band_item {
  min-height: 110px !important;
  margin-bottom: 16px;
  width: calc((100% - 80px) / 6);
  height: auto;
  border-radius: 8px;
  overflow: hidden;
}
.band_item:not(:nth-child(6n)) {
  margin-right: 16px;
}
.band_item * {
  width: 100%;
  height: auto;
  display: block;
}

.band_cover_img {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.band_name {
  position: absolute;
  padding: 8px;
  bottom: 0;
  right: 0;
  left: 0;
  font-size: 13px !important;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #fff;
  z-index: 1;
  box-sizing: border-box;
}

.band_item {
  position: relative;
  width: calc((100% - 80px) / 6);
  height: 0 !important;
  padding-bottom: calc((100% - 80px) / 6);
}

.band_cover_img {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.band_cover_img .linear {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.band_cover_img img {
  width: 100%;
  height: 100% !important;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.band_name {
  z-index: 2 !important;
}

/***************************************************************************
                            5.responsive
***************************************************************************/
@media (max-width: 768px) {
  .slider_s {
    display: none;
  }
  .slider_m {
    display: block;
  }
  .slider_l {
    display: none;
  }
  .image-gallery-slide {
    height: 40vw;
  }
  .image-gallery-slide .image-gallery-description {
    padding: 16px 20px;
    font-size: 18px;
    line-height: 24px;
  }

  .image-gallery-slide .image-gallery-description.busan {
    padding: 16px 20px;
    font-size: 4vw;
    line-height: 5.2vw;
    bottom: 10vw;
  }
  .image-gallery-slide .image-gallery-description.busan button {
    margin-top: 8px;
    padding:6px 8px;
    font-size: 16px;
  }
  .homeMenuButtonWrapper .sc-ifAKCX.jsjrWJ button {
    font-size: 16px;
  }
  .band-guide-area.title {
    font-size: 16px;
    margin-bottom: 4px;
  }
  .band_item {
    margin-bottom: 12px;
    padding-bottom: calc((100% - 36px) / 4);
    width: calc((100% - 36px) / 4) !important;
    border-radius: 6px;
  }
  .band_item:not(:nth-child(6n)) {
    margin-right: 0px;
  }
  .band_item:not(:nth-child(4n)) {
    margin-right: 12px;
  }
}
@media (max-width: 500px) {
  .slider_s {
    display: block;
  }
  .slider_m {
    display: none;
  }
  .slider_l {
    display: none;
  }
  .image-gallery-slide {
    height: 55vw;
  }


  
  .image-gallery-slide .image-gallery-description.busan {
    padding: 12px 0;
    font-size: 5.6vw;
    line-height: 6.4vw;
    bottom:13vw;
  }
  
  .homeMenuButtonWrapper .sc-ifAKCX.jsjrWJ button {
    margin: 2px;
    padding: 4px;
    font-size: 15px;
  }
  .leaderWrap button {
    font-size: 12px;
  }
  .band_item {
    width: calc((100% - 24px) / 3) !important;
    padding-bottom: calc((100% - 24px) / 3);
  }
  .band_item:not(:nth-child(6n)),
  .band_item:not(:nth-child(4n)) {
    margin-right: 0px;
  }
  .band_item:not(:nth-child(3n)) {
    margin-right: 12px;
  }
}
