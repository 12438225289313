.fr-image-upload-layer.fr-active.fr-layer {
    font-size: 0;
}

.fr-video-upload-layer.fr-layer.fr-active {
    font-size: 0;
}

.fr-file-upload-layer.fr-layer.fr-active{
    font-size: 0;
}

:focus-ring {
	outline: 2px solid blue;
}