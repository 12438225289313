/* Photo Grid CSS */
._attachmentPhotosRegion {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 1rem;
}

.uWidget {
    z-index: 1;
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    min-height: 65px;
    margin: 10px 0 0;
    text-align: left;
    font-size: 0;
}

.gCursorPointer {
    cursor: pointer !important;
}

.uCollage {
    overflow: hidden;
    position: relative;
    border: 0;
    background: 0 0;
    font-size: 0;
    cursor: pointer;
}

.uCollage .collageImage:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.05);
    content: "";
}

.uCollage .collageImage img {
    width: 100%;
    vertical-align: middle;
}

.uCollage .collageImage {
    width: 100%;
    display: block;
}

.uCollage .collageItem {
    overflow: hidden;
    position: relative;
    float: left;
    background-color: #fafafa;
    font-size: 0;
    cursor: pointer;
}

.uCollage .moreMedia .moreText {
    text-align: center;
    color: #f4f4f4;
}

.uCollage .moreMedia {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    font-size: 16px;
    width: 100%;
}

.uCollage .moreMedia .moreText:after {
    content: "+";
    position: relative;
    top: -1px;
    margin-left: 3px;
}

/* 사진이 1개 있을때  */

.uCollage.-horizontal[data-collage="1"] .collageItem {
    min-height: 80px;
}

.uCollage[data-collage="1"] .collageItem {
    float: none;
}

.uCollage[data-collage="1"] .collageImage img {
    max-height: 500px;
}

/* 사진이 2개 있을때   
  vertical */

.uCollage.-vertical[data-collage="2"] .collageItem {
    max-height: 371px;
}

.uCollage[data-collage="2"] .collageItem + .collageItem {
    margin-left: 2px;
}

.uCollage[data-collage="2"] .collageItem {
    width: 49.3181%;
    width: calc(50% - 1px);
}

/* horizontal  */
.uCollage.-horizontal[data-collage="2"] .collageItem {
    width: 100%;
    max-height: 248px;
}

.uCollage.-horizontal[data-collage="2"] .collageItem + .collageItem {
    margin-top: 2px;
    margin-left: 0;
}

/* 사진이 3개 있을때 vertical*/
.uCollage.-vertical[data-collage="3"] .collageItem {
    width: 57.1%;
    width: calc(57.26% - 1px);
    max-height: 422px;
}

.uCollage.-vertical[data-collage="3"] .collageItem:not(:nth-child(1)) {
    width: 42.34%;
    width: calc(42.55% - 1px);
    max-height: 210px;
    margin-left: 2px;
}

.uCollage.-vertical[data-collage="3"] .collageItem {
    width: 57.1%;
    width: calc(57.26% - 1px);
    max-height: 422px;
}

.uCollage.-vertical[data-collage="3"] .collageItem:nth-child(n + 3) {
    margin-top: 2px;
}

/* square */

.uCollage.-square[data-collage="3"] .collageItem {
    width: 66.54%;
    width: calc(66.74% - 1px);
    max-height: 330px;
}

.uCollage.-square[data-collage="3"] .collageItem:not(:nth-child(1)) {
    width: 33.05%;
    width: calc(33.26% - 1px);
    max-height: 164px;
    margin-left: 2px;
}

.uCollage.-square[data-collage="3"] .collageItem {
    width: 66.54%;
    width: calc(66.74% - 1px);
    max-height: 330px;
}

.uCollage.-square[data-collage="3"] .collageItem:nth-child(n + 3) {
    margin-top: 2px;
}

/* horizontal */
.uCollage.-horizontal[data-collage="3"] .collageItem {
    width: 100%;
    max-height: 248px;
}

.uCollage.-horizontal[data-collage="3"] .collageItem:not(:nth-child(1)) {
    width: 49.3181%;
    width: calc(50% - 1px);
    max-height: 247px;
    margin-top: 2px;
}

.uCollage.-horizontal[data-collage="3"] .collageItem:nth-child(n + 3) {
    margin-left: 2px;
}

/* 사진 4개이상일때 vertical  */
.uCollage.-vertical[data-collage="4"] .collageItem {
    width: 66.54%;
    width: calc(66.74% - 1px);
    max-height: 496px;
}

.uCollage.-vertical[data-collage="4"] .collageItem:not(:nth-child(1)) {
    width: 33%;
    width: calc(33.198% - 1px);
    max-height: 164px;
    margin-left: 2px;
}

.uCollage.-vertical[data-collage="4"] .collageItem:nth-child(n + 3) {
    margin-top: 2px;
}

/* horizontal */
.uCollage.-horizontal[data-collage="4"] .collageItem {
    width: 100%;
    max-height: 330px;
}

.uCollage.-horizontal[data-collage="4"] .collageItem:not(:nth-child(1)) {
    width: 33.1%;
    width: calc(33.24% - 1px);
    margin-top: 2px;
}

.uCollage.-horizontal[data-collage="4"] .collageItem:nth-child(n + 3) {
    margin-left: 2px;
}

/* square */

.uCollage.-square[data-collage="4"] .collageItem {
    width: 49.3181%;
    width: calc(50% - 1px);
    max-height: 247px;
    margin-right: 2px;
}

.uCollage.-square[data-collage="4"] .collageItem:nth-child(even) {
    margin-right: 0;
}

.uCollage.-square[data-collage="4"] .collageItem:nth-child(n + 3) {
    margin-top: 2px;
}

.uCollage .player.-size80 .collageImage:before {
    width: 80px;
    height: 80px;
    overflow: hidden;
    display: inline-block;
    content: "";
    background-size: 70px 70px !important;
    background: url("../../assets/images/play-button-black.png");
    background-position: 0 0;
    background-repeat: no-repeat;
}

.uCollage .player.-size80 .collageImage:hover:before {
    background-size: 70px 70px !important;
    background: url("../../assets/images/play-button-white.png");
    background-repeat: no-repeat;
}

.uCollage .player .collageImage:before {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.player.-size80 .collageImage:before {
    width: 80px;
    height: 80px;
    overflow: hidden;
    display: inline-block;
    content: "";
    background-size: 70px 70px !important;
    background: url("../../assets/images/play-button-black.png");
    background-position: 0 0;
    background-repeat: no-repeat;
}

.player.-size80 .collageImage:hover:before {
    background-size: 70px 70px !important;
    background: url("../../assets/images/play-button-white.png");
    background-repeat: no-repeat;
}

.player .collageImage:before {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
