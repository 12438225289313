@charset "utf-8";

$keyColor: #51A5C1;
$url-images: "../images/";
$radioCheckedImg: "../../assets/images/radio-checked.svg";
$radioUncheckedImg: "../../assets/images/radio-unchecked.svg";


.itemListTitle {
  object-fit: contain;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.38;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.list_fixed_null {
  width: 100%;
  height: 156px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9fb;
  padding: 0;
  border-top: 1px solid #80808063;
  border-bottom: 1px solid #80808063;
  color: grey;
}

.list_unfixed {
  background-color: #f9f9fb;
  overflow-y: scroll;
  height: 156px;
  padding: 0;
  border-top: 1px solid #80808063;
  border-bottom: 1px solid #80808063;
}

.list_unfixed .flex {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.list_unfixed .thumbnail {
  width: 32px;
  height: 32px;
  object-fit: contain;
  border-radius: 3px;
  margin: 5px 10px;
}

.list_unfixed .btn_fix {
  width: 48px;
  max-width: 48px;
  height: 20px;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: center;
  color: $keyColor;
}

.list_unfixed .placeholder {
  object-fit: contain;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #787879;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list_unfixed .btn_unfix {
  width: 48px;
  height: 20px;
  object-fit: contain;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: center;
  color: $keyColor;
}

.flex .icon_sorting {
  width: 12px;
  height: 8.6px;
  object-fit: contain;
  margin-left: 8px;
}

.modal_wrap {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
}

.modal_wrap .subtitle {
  object-fit: contain;
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  margin-top: -21px;
  margin-bottom: 20px;
  color: #8e8e93;
}

.modal_wrap .modal {
  vertical-align: middle;
  background-color: #fff;
  margin: 0 auto;
  z-index: 9999999;
  min-width: 300px;

  // @media all and (max-width: 450px) {
  //   width: 100%;
  //   margin: 0 16px 0 16px;
  // }

  // @media all and (min-width: 450px) and (max-width: 768px) {
  //   width: 70%;
  // }

  // @media all and (min-width: 768px) {
  //   width: 40%;
  // }

  // @media all and (min-width: 1200px) {
  //   width: 25%;
  // }

  // @media all and (min-width: 1500px) {
  //   width: 20%;
  // }
}

.modal_wrap .category {
  vertical-align: middle;
  background-color: #fff;
  margin: 0 auto;
  padding: 2rem 0;
  width: 365px;
  z-index: 99999;
}

.modal_wrap .modal .modal_content {
  margin: 0 auto;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;

  .title{
    display: inline-block;
    font-size: 2rem;
    font-weight: bold;
  }

  p {
    display: inline-block;
    font-size: 1rem;
    font-weight: bold;
  }

  label {
    cursor: pointer;
  }
}

.modal_wrap .modal .modal_content {
  &.view_order {
    text-align: left;
    flex-direction: column;
  }
}

.modal_wrap .modal .modal_content {
  &.view_type {
    .img {
      width: 60px;
      height: 125px;
      margin-bottom: 1.2rem;
      margin-left: 5px;
    }

    .view_type_card {
      margin-right: 4rem;

      .img {
        background: url($url-images+"main_img.png") -60px -82px;
      }
    }

    .view_type_card_active {
      margin-right: 4rem;

      .img {
        background: url($url-images+"img-type-card.svg");
      }
    }

    .view_type_list {
      .img {
        background: url($url-images+"main_img.png") -180px -82px;
      }
    }
    .view_type_list_active {
      .img {
        background: url($url-images+"img-type-list.svg");
      }
    }
  }
}

.modal_wrap_menu_display {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 5;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  
  .modal {
    color: white;
    padding: 3rem;
    margin-bottom: 25px;
    text-align: right;
    line-height: 3;
    font-size: 1.7rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    letter-spacing: 2.4px;
    z-index: 99999;
  }
  
  .close_btn {
    width: 23px;
    height: 23px;
    background: url($url-images+"main_img.png") -64px 0;
    display: block;
    float: right;
    cursor: pointer;
  }

}

.modal_content_userProfile {
  text-align: center;
  .myThumb {
    width: 80px !important;
    height: 80px;
    object-fit: contain;
    border-radius: 50%;
  }

  .userName {
    object-fit: contain;
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: 3px;
    text-align: center;
    color: #000000;
  }

  .container {
    box-sizing: content-box;
    overflow: visible;
    height: 1px;
    color: #c7c7cc;
    background-color: #c7c7cc;
    border: none;
  }

  .userInfo {
    object-fit: contain;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #232323;
  }

  .btn_editPic {
    height: 24px;
    width: 24px;
    object-fit: contain;
    margin-left: -20px;
  }

  .label_nickName {
    display: flex;
    object-fit: contain;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #232323;
  }

  .inputBox {
    object-fit: contain;
    border-radius: 6px;
    height: 24px;
    border: none;
    background-color: #efeff4;
    flex: 80%;
    margin-left: 10px;
    padding-left: 10px;
  }
}

.modal_wrap .close_btn {
  width: 23px;
  height: 23px;
  background: url($url-images+"main_img.png") -64px 0;
  display: block;
  float: right;
  cursor: pointer;
}

.modal_wrap .modal .title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 26px;
  display: inline-block;
}

.modal_wrap .modal .iconNope {
  width: 38.9px;
  height: 80px;
  display: flex;
  margin: 15px auto;
  justify-content: center;
}

.modal_wrap .modal_submit_btn {
  background-color: $keyColor;
  border-radius: 6px;
  width: 100%;
  height: 44px;
  font-size: 1rem;
  color: #fff;
  margin-top: 26px;
}


.modal_content.view_order.content_grid {
  margin-bottom: -20px;
}