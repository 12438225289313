.con-header {
    display: flex;
    align-items: center;
}
.roll-back {
    margin-right: 12px;
    cursor: pointer;
    width: 10px;
    height: 24px;
}
 .roll-back img {
    width: 100%;
    height: auto;
}
.page-title {
    display: inline-block;
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #2c70b9;
    line-height: 1.2;
}
.page-title-sub {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #cbd4d4;
    line-height: 1.4;
}
.page-title-sub:before {
    content: "|";
    margin: 0 8px;
    font-size: 14px;
    vertical-align: 2px;
}