.headerWrap-band {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 56px;
    border-bottom: 1px solid #dbdbdb;
    background: rgba(255, 255, 255, 1);
    z-index: 1002;
}

@media (max-width: 500px) {
    .headerWrap-band {
        display: none;
    }
}

.headerContentsWrap-band {
    max-width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: stretch;
    padding: 0px 16px;
}

.logoWrap-band {
    flex: none;
    width: 76px;
    height: 100%;
    padding: 10px 0px;
    box-sizing: border-box;
    display: inline-block;
    margin-right: 36px;
    cursor: pointer;
}

.gnbWrap-band {
    display: inline-block;
    flex: none;
    height: 100%;
    margin-right: 16px;
}
.gnbWrap-band .menu-band {
    border: 1px solid rgb(128, 128, 128);
    font-size: 16px;
    color: rgb(128, 128, 128);
    line-height: 20px;
    font-weight: bold;
    padding: 4px 6px;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 13px 4px 13px 0px;
}
.gnbWrap-band .menu-band.select {
    background: #51a5c1;
    border: 1px solid #51a5c1;
    color: #fff;
}
.gnbWrap-band .menu-band:last-child {
    margin-right: 0px;
}

.lnbWrap-band {
    display: flex;
    flex: none;
    align-items: center;
    margin-right: 16px;
    margin-left: auto;
}
.lnbWrap-band .menuLnb-band {
    margin-right: 10px;
}
.lnbWrap-band .menuLnb-band:last-child {
    margin-right: 0px;
}
.lnbWrap-band .menuLnb-band a {
    font-size: 17px;
    line-height: 20px;
    color: #808080;
    padding: 5px;
    margin-right: 10px;
    cursor: pointer;
}
.lnbWrap-band .menuLnb-band.selectedLnb a {
    color: #51a5c1;
}

.userWrap-band {
    flex: none;
    width: 36px;
    height: 36px;
    margin: 10px 0px;
    cursor: pointer;
}
