.band-create-contentsWrap {
    position: relative;
    margin: 0 auto;
    padding: 20px;
    max-width: 900px;
    border: 1px solid rgb(219, 219, 219);
    border-radius: 4px;
    background-color: #fff;
    box-sizing: border-box;
}
.con-header-top {
    display: flex;
    align-items: center;
}
.roll-back-top {
    margin-right: 12px;
    cursor: pointer;
    width: 10px;
    height: 24px;
}
.page-title-top {
    display: inline-block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
        Helvetica Neue, sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #2c70b9;
    line-height: 1.2;
}
.page-title-sub-top {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
        Helvetica Neue, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #cbd4d4;
    line-height: 1.4;
}
.page-title-sub-top:before {
    content: "|";
    margin: 0 8px;
    font-size: 14px;
    vertical-align: 2px;
}


.page-title-top-home {
    display: inline-block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
        Helvetica Neue, sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    line-height: 1.2;
    text-shadow: 0 0 4px #000000d1;
}

.page-title-sub-top-home {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
        Helvetica Neue, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    line-height: 1.4;
    text-shadow: 0 0 4px #000000d1;
}

.page-title-sub-top-home:before {
    content: "|";
    margin: 0 8px;
    font-size: 14px;
    vertical-align: 2px;
}


.page-title-top-home-scroll {
    display: inline-block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
        Helvetica Neue, sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #2c70b9;
    line-height: 1.2;
}
.page-title-sub-top-home-scroll {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
        Helvetica Neue, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color:#2c70b9;
    line-height: 1.4;
}
.page-title-sub-top-home-scroll:before {
    content: "|";
    margin: 0 8px;
    font-size: 14px;
    vertical-align: 2px;
}
