@charset "utf-8";

.band_grid {
  margin: 0px 16px 0px 16px;

  @media all and (max-width: 768px) {
    .band_item:nth-child(odd) + .band_join {
      display: inline-block;
    }
  }
}

.band_cover_img {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .linear {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 6px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.75));
    cursor: pointer;
  }

  img {
    width: 100%;
    border-radius: 6px;
    object-fit: cover;
  }

  .band_join_button {
    width: 100%;
    display: block;
    margin: auto;
  }
}
