.uc-radio {
  -webkit-appearance: none;
  background-position: 0px 5px;
  background-size: 15px;
  background-repeat: no-repeat;
  outline: none;
  background-image: url(./assets//images/radio-unchecked.svg);

  &:checked {
    background-image: url(./assets/images/radio-checked.svg);
  }
}
