.BrainhubCarousel ul{padding-bottom: 4px !important;}
@media (min-width: 1150px) {
    .search-btnPrev,
    .search-btnNext {
        opacity: 0 !important;
    }
}

@media (max-width: 1200px) {
    .search-noInput, .searchBoxWrap{
        margin-bottom: 4%;
    }
}


@media (max-width: 1024px) {
    .search-noInput, .searchBoxWrap{
        margin-bottom: 3%;
    }
}


@media (max-width: 768px) {
    .search-noInput, .searchBoxWrap{
        margin-bottom: 6%;
    }
}

