main {
    display: block;
}

/*layout*/

.con-lft {
    float: left;
    width: 245px;
    position: relative;
    margin-right: 16px;
    padding-bottom: 20px;
}
.con-center {
    float: left;
    width: calc(100% - 32px - 490px);
    padding-bottom: 20px;
}
.con-rht {
    float: left;
    width: 245px;
    position: relative;
    margin-left: 16px;
    padding-bottom: 20px;
}
.clearfix {
    clear: both;
}
.sticky {
    position: fixed;
    width: 245px;
}
/*con-lft*/
.makeBand_rec {
    width: 100%;
    height: auto;
    display: inline-block;
}
.makeBand_rec img {
    width: 100%;
    height: auto;
    display: block;
}
.myBandList h5 > .count {
    font-size: 16px;
    font-weight: bold;
    color: #00a5c1;
    line-height: 24px;
    margin-left: 6px;
}
.myBandList .goToBand {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}
.myBandList .goToBand .bandThumb {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    margin-right: 10px;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
}
.myBandList .goToBand .label_bandName {
    font-size: 13px;
    line-height: 21px;
    color: #808080;
}
.myBandList .goToBand:hover .label_bandName {
    color: #00a5c1;
}
/*con-center*/
.singleCardWrap {
    background-color: #fff;
    margin-bottom: 20px;
}
.singleCardWrap > .bandName {
    display: block;
    padding: 4px 16px;
    font-size: 13px;
    line-height: 21px;
    font-weight: bold;
    border-bottom: 1px solid #dbe5eb;
}
.cardHeader {
    padding: 14px 16px;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.writerThumb {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-right: 16px;
    flex:0 0 auto;
}
.singleCardWrap .cardInfo .writerNamePosition {
    font-weight: bold;
}
.singleCardWrap .cardInfo .position:before {
    content: "|";
    margin: 0px 4px;
    color: #d4d4d4;
}
.singleCardWrap .cardInfo .position,
.singleCardWrap .cardInfo .date {
    font-size: 13px;
    line-height: 17px;
    color: #aeaeaf;
}
.singleCardWrap .cardContents {
    padding: 0px 16px;
}
.singleCardWrap .cardContents p,
.singleCardWrap .cardContents img {
    margin-bottom: 8px;
}
.singleCardWrap .cardContents img {
    width: 100%;
    height: auto;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
}
.singleCardWrap .cardCount {
    padding: 0px 16px 4px;
    border-bottom: 1px solid #dbe5eb;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.singleCardWrap .cardCount * {
    font-size: 13px;
    line-height: 21px;
    color: #aeaeaf;
}
.singleCardWrap .cardCount .emotion,
.singleCardWrap .cardCount .reply {
    margin-right: 16px;
}
.singleCardWrap .cardCount .view {
    margin-left: auto;
}
.singleCardWrap .cardAction .addEmotion,
.singleCardWrap .cardAction .addReply {
    width: 50%;
    padding: 12px 16px;
    display: inline-block;
    float: left;
    text-align: center;
    box-sizing: border-box;
}
.singleCardWrap .cardAction .addEmotion {
    border-right: 1px solid #dbe5eb;
}
.singleCardWrap .cardAction .addEmotion:before {
    margin-right: 8px;
    vertical-align: -2px;

}
.singleCardWrap .cardAction .addReply:before {
    margin-right: 8px;
    vertical-align: -2px;
}
.singleCardWrap .cardAction .addEmotion:hover,
.singleCardWrap .cardAction .addReply:hover {
    text-decoration: underline;
}
/*con-rht*/
.con-rht .sticky {
    background-color: #fff;
    padding: 12px 16px;
    box-sizing: border-box;
}
.explore-cat-news {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: row wrap;
}
.explore-cat-news li {
    width: calc((100% - 16px) / 3);
    margin-right: 8px;
    margin-bottom: 8px;
}
.explore-cat-news li:nth-child(3n) {
    margin-right: 0px;
}
.explore-cat-news li * {
    width: 100%;
    height: auto;
}
.explore-cat-news li a img {
    display: block;
    box-shadow: 2px 2px 4px rgba(0, 165, 193, 0.2);
    border-radius: 6px;
}
/***************************************************************************
                            5.responsive
***************************************************************************/
@media (max-width: 1024px) {
    .con-lft,
    .con-rht,
    .sticky {
        width: 200px;
    }
    .con-center {
        width: calc(100% - 32px - 400px);
    }
    h5,
    .myBandList h5 > .count {
        font-size: 14px;
    }
}
@media (max-width: 768px) {
    .con-lft,
    .con-rht {
        display: none;
    }
    .con-center {
        width: 100%;
    }
    .contentsWrap {
        padding: 16px 0px 50px;
    }
}
